.user-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.user-info ,.course-progression{
    width: 85vw;
    color: #FFF;
    margin: auto;
    margin-top: 100px;
}

.name-and-profile-pic{
    display: flex;
    align-items: center;
}


.bio-wrapper{
    padding-top: 20px;
    align-self: center;
    width:40%;
}

.edit-icon{
    cursor: pointer;
}

.name-and-profile-pic div{
    padding-left: 20px;
}

.profile-pic{
    border-radius:50% ;
    object-fit: cover;
    width: 150px;
    height: 150px;
}

.user-name{
    padding-bottom: 10px;
}
.user-name, .enrolled-as, .bio{
    font-family: bitter;    
}


.course-progression-header{
    color: var(--dark-light, #F1F1F3);
    font-family: bitter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 10px;
}


.progression-card{
    display: inline-flex;
    flex-direction: column;
    height: min(300px, 35vh);
    flex-shrink: 0;
    border-radius: 16px;
    padding: 40px 100px 0 50px;
    border: 3px solid rgba(105, 120, 255, 0.288);
    background: linear-gradient(109deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
    box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(50px);
}


.level{
    font-family: bitter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding-top: 30px;
}

.progress-bar{
    color: #FFF;
    font-family: bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    padding-bottom: 50px;
}

.progress-percentage{
    color: #8BFF33;
}

.unto-the-next{
    padding: 20px 10px;
    width: 60%;
    margin-top: -10%;
    border-radius: 10px;
    background: #8B03FB;
    border: none;
    color: #F1F1F3;
    font-size: 14px;
    cursor: pointer;
}


.certificates-header{
    color: #F1F1F3;
    font-family: bitter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    margin: 100px auto 0 auto;
}

.the-certificates{
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    flex-wrap: wrap;
    gap: 20px;
}

.the-certificates div{
    width: 590px;
    height: 481px;
    border-radius: 5px;
    background: #1C0132;

}

.certificates{
    width: 85%;
    margin: auto;

}

.not-user{
    color: #F1F1F3;
    width: 80%;
    margin: 100px auto 0;
    font-family: bitter;
}

.redirect-to-enroll{
    width: 150px;
    background-color: #8B03FB;
    margin-top: 10px;
    border-radius: 20px;
    height: 40px;
    border: none;
    font-size: 14px;
    cursor: pointer;
}

.redirect-to-enroll a{
    color: #F1F1F3;
    text-decoration: none;
}
@media screen and (max-width:800px) {
    .bio-header{
        font-size: 18px;
    }

    .bio{
        font-size: 12px;
    }
}


@media screen and (max-width:650px){

    .profile-pic{
        width: 100px;
        height: 100px;
    }
    .user-info{
        flex-direction: column;
        align-items: flex-start;
    }

    .bio-wrapper{
        align-self: flex-start;
        width: 100%;
        padding-top: 48px;
    }

    .course-progression-header{
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px
    }

    .level{
        font-family: bitter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    .progression-card{
        width: 330px;
        height: 215px;
        padding: 5px 15px;
        margin-left: -10px;
    }

    .unto-the-next{
        margin-top: -40px;
        width: 70%;
        font-size: 12px;
    }
}