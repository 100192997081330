

.your-enrollment-header{
    color: #F1F1F3;
    font-family: Bitter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 108px; /* 150% */
    letter-spacing: 6.221px;
    margin-bottom: 40px;
}


.course-content-header{
    color:  #F1F1F3;
    font-family: bitter;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 120% */
    letter-spacing: 1.92px;
    margin-bottom: 4vh;
}

.curriculum{
    width: 70vw;
    margin-left: 15%;
    

}


.course-curriculum{
    color:rgb(250, 250, 250);

}

.curriculum-content{
    margin-bottom: 5%;
    width: 98%;

}

.curriculum-content div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 6vh; */
}

.curriculum-content div{
    /* width: 98%; */
    /* display: inline-flex;
    justify-content: space-between; */
    color: #F1F1F3;
    font-family: Bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 150% */
    letter-spacing: 0.307px;
}

#header{
    width: 50%;
}





/* .curriculum-content li:not(:first-child){
    opacity: 0.3;
} */
/* .curriculum-content li::before{
    content: ".";
    padding-right: -20px;
} */

.curriculum-content hr{
    /* height: 12px; */
    border: .5px solid #D19AFE;
    background-color: #D19AFE;

}

.upcoming, .done{
    opacity:.5 ;
}


.live{
    opacity: 1;
}


.curriculum-content button{
    display: inline-flex;
    padding: 8px 22px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    background: #8B03FB;
    color: #F1F1F3;
    border: none;
    cursor: pointer;
}

@media screen and (max-width:800px) {


    .your-enrollment-header{
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 3px;
    }

    .curriculum{
        width: 85vw;
       margin: auto;
    }

    .curriculum-content li{
        font-size: 14px;
    }
}

@media screen and (max-width:600px) {
    .curriculum-content li{
        font-size: 10px;
    }

    .course-content-header{
        font-size: 24px;
    }
    .your-enrollment-header{
        font-size: 26px;
    }
}

@media screen and (max-width:420px){
    .curriculum{
        width: 90vw;
    }

    .curriculum-content h3, #class-period, #action-btn{
        font-size: 12px;
        line-height: 1.8em;
        font-weight: 600;
    }

    .course-content-header{
        font-size: 18px;
    }
    .your-enrollment-header{
        font-size: 22px;
        letter-spacing: 1px;
    }

    
}