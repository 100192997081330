


.first-and-lastname{
    display: flex;
    justify-content: space-between;
}



/* .first-and-lastname input{
    background-color: rgba(93, 93, 111, 0);
    padding: 16px 100px 16px 16px;
    justify-content: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid var(--dark-dark, #5D5D6F);
    margin-bottom: 5vh;
    color: #F1F1F3;
    position: relative;

} */

/* .firstName-label , .lastName-label{
    color: #F1F1F3;
    font-family: Libre Franklin;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding-left: 3px;
} */


/* .email, .phone, .password{
    color: #F1F1F3;
    padding: 16px 395px 16px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 5vh;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid var(--dark-dark, #5D5D6F);
    background-color: #5d5d6f00;
} */

.login-form label{
    color: #F1F1F3;
    font-family: bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding-left: 3px;
}


/* .submit{
    margin: auto;
    display: flex;
    color: #F1F1F3;
    width: min(550px, 90%, 70vw);
    padding: 16px 248px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    background-color:  #8B03FB;
} */

/* 

.alternative{
    padding:30px 0 ;
    display: flex;
    flex-direction: column;
    text-align: center;
} 

*/
/* 
.alternative p{
    color: #F1F1F3;
    font-family: Libre Franklin;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 12px;
}
 */



/* Login styles *******************************************************/
/* Login styles *******************************************************/
/* Login styles *******************************************************/


.login-screen-wrapper{
    width: 40vw;
    margin: 100px auto 0 auto;
}



.login, .login-form, .email-login, .password-login, .sign-in-options{
    display: flex;
    flex-direction: column;
}

.sign-in-options{
    gap: 10px;
}

.sign-in-options p{
    font-family: bitter;
}


.sign-in-options button{
    border-radius: 10px;
    border: 1px solid var(--dark-dark, #5D5D6F);
    /* height: 50px; */
    font-family: bitter;
    font-size: 16px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-items: center;
    padding: 12px 170px;
}


.sign-in-with-google{
    background: #f0e2fc;
}
.sign-in-with-facebook{
    justify-self: center;
    text-align: center;
    color: #E8FAFD;
    background: #5911ff;
}

.login, .login-form{
    gap: 35px;
}

.login-form label{
    color: var(--dark-light, #F1F1F3);
    font-family: bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}


.login-form input{
    background-color: #5d5d6f00;
    border-radius: 10px;
    border: 1px solid var(--dark-dark, #5D5D6F);
    padding: 16px 300px 16px 16px;
    color: #E8FAFD;

}


.continue-to-signin{
   display: inline-flex;
   padding: 16px 230px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 10px;
   background: var(--primary-normal-active, #8B03FB);
   color: #E8FAFD;
   text-align: center;
   font-family: bitter;
   font-size: 16px;
   font-style: normal;
   font-weight: 700;
   line-height: 24px;
   margin: auto;
}

.alternatively-signup{
    width: 80%;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;

}

.alternatively-signup a {
    color: #F1F1F3;
    text-decoration: none;
    color: var(--primary-normal-active, #8B03FB);
    font-family: bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px
}


.password-error, .email-and-pass-error{
    color: rgb(192, 5, 5);
    font-size: 14px;
    margin-bottom: 5px;
    font-style: italic;

}

@media (max-width:1300px){
    .continue-to-signin{
        padding: 16px 180px;
    }

    .login-screen-wrapper{
        width: 512px;
    }
}


@media screen and (max-width:600px) {

    .login-screen-wrapper{
        width: 412px;
    }

    .continue-to-signin{
        padding: 16px 160px;
    }

    .sign-in-options button{
        padding:10px 120px ;
    }
    
}

@media screen and (max-width:420px) {
    .login-screen-wrapper{
        width: 350px;
    }

    .continue-to-signin{
        padding: 16px 130px;
    }

    .sign-in-options button{
        padding:10px 78px ;
        font-size: 16px;
    }

    .sign-in-choice-text{
        font-size: 18px;
    }

    .login-form input{
        display: inline-flex;
        padding: 16px 188px 16px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .password-error , .email-and-pass-error{
        font-size: 12px;
        font-style: italic;
    }

}