.full-onboarding{
    display: flex;
    overflow: hidden;
}


#carousel{
    transition: transform .5 ease-in-out;
}

.carousel-item{
    overflow: hidden;
}
