.first-page{
    background-color: rgba(0, 0, 16, 1);
    width: 99.99vw;
    height: 100%;
    position: relative;
    color: rgba(241, 241, 243, 1);
}


.circle1{
    width: 317px;
    height: 317px;
    flex-shrink: 0;
    border-radius: 317px;
    background: #8B03FB;
    filter: blur(196.5px);
    position: absolute;
    right: 0;
    top: -30vh;
}
.circle2{
    width: 35vw;
    height: 35vh;
    flex-shrink: 0;
    border-radius: 317px;
    background: #8B03FB;
    filter: blur(196.5px);
    position: absolute;
    right: 25vw;
    bottom: 2vh;
}

.first-page-content-container{
    padding-top: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcome-greeting{
    color: rgba(241, 241, 243, 1);
    text-align: center;
    font-family: Merriweather;
    font-size: 5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 10.7vh;
}

.hi{
    color: #8B03FB;
}

.invitation{
    color: var(--dark-light, #F1F1F3);
    text-align: center;
    font-family: Libre Franklin;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

.continue-btn{
    transform:translateX(260%) ;
    font-family: Libre Franklin;
    width: 16vw;
    height: 6vh;
    border-radius: 6px;
    background: var(--primary-normal-active, #8B03FB);
    color: #ffffff;
    font-size: 1vw;
    cursor: pointer;
    margin-top: 3vh;
    transition: .5s ease-in-out;
    border: none;
    pointer-events: all;
}

.continue-btn:hover{
    background-color: #000010;
    border: 2px solid #8B03FB;

    color: #F1F1F3;
}

.quit-story{
    float: right;
    width: 30vw;
    height: 3vh;
    background-color: #B91321;
    border: none;
    border-radius: 11px;
}

.onboarding-screen{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    width: 98vw;
    height: 100vh;
    margin: auto;
    
}

.currentPage{
    width: 100vw;
}

.carousel-cont{
    width:500vw;
}



.onboarding-screen-wrapper{
    background: var(--background, #000010);
}

.onboarding-page-circle{
    border-radius: 317px;
    background: #8B03FB;
    filter: blur(196.5px);
    width: 28vw;
    height: 28vh;
    flex-shrink: 0;
    border-radius: 317px;
    background: #8B03FB;
    filter: blur(196.5px);
    position: absolute;
}



.header{
    color: rgba(241, 241, 243, 1);
    font-family: Merriweather;
    font-size: 5vw;
    font-style: normal;
    font-weight: 700;
    text-align:justify;
    width: 100%;
}

#slide-5 h2{
    width: 21vw;
}


.body{
    color: var(--dark-light, #F1F1F3);
    white-space: pre-line;
    width: 40vw;
    font-family: Libre Franklin;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
}

.image-container{
    width: 40vw;
    padding-left: 5vw;
}


.image-container img{
    width: 80%;
    height: 80%;
}

.button-wrapper{
    margin-top: 5vh;
    width: 25vw;
    display: flex;
    justify-content: space-between;
}

.next, .prev{
    width: 10vw;
    height: 3vw;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.next{
    background: var(--primary-normal-active, #8B03FB);
}

.next:hover{
    background: var(--primary-normal-active, #000010);
    border: 1px solid var(--primary-normal-active, #8B03FB);
}

.prev{
    background-color: #000010;
    border-radius: 6px;
    border: 1px solid var(--primary-normal-active, #8B03FB);
}


.prev:hover{
    background-color: #8B03FB;
    border-radius: 6px;
}


.everything{
    display: flex;
}
