.enroll-basic{
    width: 100%;
    margin: auto;
    background: #F3E6FF;
}

.enroll-basic-header{
    margin: auto;
    padding-top: 20px;
    width: 80%;
    color:rgb(0, 0, 0);
    font-size:36px ;
    font-family:Merriweather;;
}

.enroll-button-wrapper{
    width: 100%;
    background-color: #F3E6FF;
    padding: 20px 0px;
}
.enroll-basic-btn, .enroll-intermediate-btn, .enroll-advanced-btn{
    width: 30%;
    margin: auto;   
    display: flex;
    justify-content: center;
    height: 48px;
    border-radius: 10px;
    background-color: #8B03FB;
    color: #F3E6FF;
    border: none;
    align-items: center;
    cursor: pointer;
}


.none{
    display: none;
}

.enroll-intermediate{
    width: 100%;
    margin: auto;
    background: #F3E6FF;
}

.enroll-intermediate-header{
    margin: auto;
    padding-top: 20px;
    width: 80%;
    color:rgb(0, 0, 0);
    font-size:36px ;
    font-family:Merriweather;;
}

/* .enroll-intermediate-btn{
    width: 30%;
    margin: auto;   
    display: flex;
    justify-content: center;
    height: 48px;
    border-radius: 10px;
    background-color: #8B03FB;
    color: #F3E6FF;
} */



.enroll-advanced{
    width: 100%;
    margin: auto;
    background: #F3E6FF;
}

.enroll-advanced-header{
    margin: auto;
    padding-top: 20px;
    width: 80%;
    color:rgb(0, 0, 0);
    font-size:36px ;
    font-family:Merriweather;;
}

.enroll-advanced-btn{
    width: 30%;
    margin: auto;   
    display: flex;
    justify-content: center;
    height: 48px;
    border-radius: 10px;
    background-color: #8B03FB;
    color: #F3E6FF;
}



@media screen and (max-width:520px) {
    .enroll-basic-header, .enroll-intermediate-header, .enroll-advanced-header{
        font-size: 24px;
    }
}