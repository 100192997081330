.home-page-navbar{
    width: 80vw;
    width: 80dvw;
    margin: auto;
    display: inline-flex;
    padding: 24px 16px;
    align-items: center;
    gap: 64px;
}

.home-page-navbar, .nav-items{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.nav-items{
    width: 40vw;
    width: 40dvw;
    list-style-type: none;
    transition: .9s ease-in-out;
    flex-wrap: wrap;
}

.nav-items img{
    display: none;
}

.nav-items a{
    color: rgba(241, 241, 243, 1);
    text-decoration: none;
}

.nav-item a{
    font-family: bitter;
    letter-spacing: 2px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.nav-item a:hover{
    color: #7725b9;
    transition: .5s ease-in-out;
}


.nav-item li a:hover::after{
    content: "";
    width: 30%;
    height: 2px;
    background-color: #7725b9;
    bottom: -4px;
    left: 20px;
}

.hamburger-menu{
    display: none;
}

/* .nav-items:last-child{
    color: #402755;
    font-size: larger;
} */



/* Mobile */


@media screen and (max-width:720px){

    nav ul#open{
        display: flex;
        animation-name:navbar ;
        animation-duration: .5s;
    }

    nav ul#close{
        animation-name:navbar-reverse ;
        animation-duration: 1s;
    }



    .hamburger-menu{
        display: block;
        position: absolute;
        right: 10vw;
        cursor: pointer;
    }


    @keyframes navbar {
        from{transform: translateX(200px);}
        to{transform: translateX(0px);}
    }


    @keyframes navbar-reverse {
        from{transform: opacity(1);}
        to{transform: opacity(200px);}
    }


    .nav-items{
        flex-direction: column;
        justify-content: unset;
        background: #1C0132;
        position: absolute;
        height: 70vh;
        width: 60vw;
        top: 2px;
        right: 5px;
        z-index: 1;
        display: none;
        transition: .5s ease-in-out;
    }


    .cancel-menu{
        position: absolute;
        right: 2px;
        cursor: pointer;
    }

    #first-nav-item{
        margin-top: 7vh ;
    }

    .nav-item{
        padding:2vh 5vw;
        height: 10vh;
    }




    .nav-items img{
        display: inline;
        padding-right:20px ;
        padding-top: 10px;
    }
}