.mentors{
    width: 80%;
    margin: auto;
}

/* .mentor-image{
    width: ;
} */

.each-mentor{
    width: 20vw;
}

.mentor-image{
    width: 100%;

}
.mentors-header{
    color: var(--dark-light, #F1F1F3);
    text-align: center;
    font-family: Bitter;
    font-size: 58px;
    font-style: normal;
    font-weight: 700;
    line-height: 108px; /* 150% */
    letter-spacing: 6.221px;
    margin-top: 5%;
}

.mentor-name{
    color: var(--dark-light, #F1F1F3);
    font-family: Bitter;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.691px;
}

.mentor-experience{
    color: var(--dark-light, #F1F1F3);
    text-align: center;
    font-family: Bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.307px;
}

.mentor-social{
    width: 100%;
    display:flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    gap: 15px;
    margin-top: 1%;
}

/* .mentor-experience, .mentor-social, .mentor-experience{
    margin-top: -5%;
} */

.mentors-wrapper{
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 5%;
    margin-bottom: 25%;
}


@media screen and (max-width:900px) {
    .mentors{
       width: 90%;
       margin: auto;
    }

    .each-mentor{
        width: 90%;
    }

    .mentors-header{
        font-size: 42px;
    }

    .mentor-name{
        font-size: 16px;
    }
    .mentor-experience{
        font-size: 12px;
    }
}

@media screen and (max-width:700px) {
    .mentor-name{
        font-size: 14px;
    }
    .mentor-experience{
        font-size: 10px;
    }

    .mentor-social img{
        width: 18px;
    }
}

@media screen and (max-width:550px) {
    .mentors-header{
        font-size: 20px;
    }
    .mentors-wrapper{
        grid-template-columns: 1fr 1fr;
        margin-bottom: 60%;
    }
}
