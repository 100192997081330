.post-blog{
    width: 80%;
    margin:auto;
    font: inter;
}

.edit-blog-header{
    color:white;
    padding: 20px;
}


.edit-post{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
    color: aliceblue;
    gap: 15px;
}


.blog-header{
    width: 100%;
    border-radius: 20px;
    padding: 10px;
    height: 80%;
    border: none;
}
.blog-body{
    border: none;
    width: 100%;
    border-radius: 20px;
    /* padding: 1px 2px 1px 2px ; */
    height: 300px;
    padding: 17px;
}



.submit-blog-post{
    width: 70%;
    margin: auto;
    border-radius: 20px;
    padding: 16px 10px 16px 0px ;
    background: #8B03FB;
    font-size: 16px;
    border: none;
    cursor: pointer;
}