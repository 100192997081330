.course{
    background: #F3E6FF;
}

.course-description{
    width: 80%;
    margin: auto;
}

.course-description-header{
    color: #0C0C0E;
    font-family: Merriweather;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    padding-top: 3%;
}

.what-you-would-learn-header{
    color: #0C0C0E;
    font-family: bitter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    padding-top: 2%;
}

.learning-outcome{
    padding-top: 2vh;
    width: 95%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.learning-outcome + div{
    width: 40%;
    display: inline-flex;
    flex-direction: column;
}

.each{
    display: inline-flex;
    justify-content: space-between;
    padding-top: 10px;

}

.check{
    margin-top: 2px;
    margin-right: 10px;
    stroke-width: 20px;
    height: 20px;
    font-size: 30px;

}

.you-will-learn{
    list-style-type: none;
    color: #0C0C0E;
    font-family: Libre Franklin;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
}



/*Select course path you woulld love to take Section */
/*Select course path you woulld love to take Section */
/*Select course path you woulld love to take Section */
/*Select course path you woulld love to take Section */
/*Select course path you woulld love to take Section */
/*Select course path you woulld love to take  Section*/
/*Select course path you woulld love to take Section */

.choose-path-header{
    color: var(--dark-light, #F1F1F3);
    text-align: center;
    font-family: Merriweather;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 112px;
}

/* .choose-path-header, .choose-path-text{
    margin-bottom: 12%;
} */

.choose-path-text{
    color: #F1F1F3;
    text-align: center;
    font-family: Libre Franklin;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}



.choose-path{
    margin-bottom: 15vh;
}





@media screen and (max-width:600px){
    .course-description-header{
        font-size: 24px;
    }

    .what-you-would-learn-header{
        font-size: 16px;
    }


    .learning-outcome{
        display: block;
    }

    .check{
        font-size: 16px;
    }

    .each li{
        font-size: 12px;
    }



    .choose-path-header{
        text-align: center;
        font-family: Merriweather;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 43px; 
    }

    .choose-path-text{
        text-align: center;
        font-family: Libre Franklin;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }


    .choose-path{
        margin-bottom: 8vh;
    }

}