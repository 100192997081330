/* .active{
    background-color: #7725b9;
} */

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


body{
    background-color: #000010;
}


.hero-section{
    width: 90dvw;
    width: 90vw;
    margin: auto;
}

.hero-header{
    color:  #F1F1F3;
    font-family: bitter;
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    margin-top: 60px;
    letter-spacing: 8px;
    line-height: 130%;
}

.hero-details{
    color: #F1F1F3;
    font-family: inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 380;
    text-align: center;
    width: 40vw;
    margin: auto;
    line-height: 27px;
    position: relative;
    letter-spacing: 0.70px;
    padding-top: .9em;
}

.circle-home{
    width: 30vw;
    height: 30vh;
    flex-shrink: 0;
    border-radius: 317px;
    background: #8B03FB;
    filter: blur(80.13641357421875px);
    position: absolute;
    right: 15vw;
    z-index: -1;
    opacity: 0.6;
}

.newsletter{
    width: 75dvw;
    width: 75vw;
    /* padding-bottom:8vh ; */
    margin: 60px auto 0 auto;
    border-radius: 50px;
    border: 3px solid rgba(255, 255, 255, 0.24);
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
    /* box-shadow: 0px 0px 50px -25px rgba(209, 134, 134, 0.5); */
    backdrop-filter: blur(200px);
    box-shadow: 4.634514331817627px 8.342124938964844px 33.368499755859375px 0px #06000b;
    
}

.newsletter-card{
    width: 80%;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    
}

.newsletter-content, .newsletter-details, .newsletter-header, .newsletter-details, .newsletter-input, .newsletter-pic-wrapper, .newsletter-pic-wrapper img, .progression-title, .progression-detials{
    background-color: rgba(0, 0, 0, 0);
    color: #F1F1F3;
}

.newsletter-content{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 30dvh;
    height: 30vh;
    width: 50%;
    align-content: center;
}

.newsletter-header{
    font-family: bitter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 150% */
    letter-spacing: 1.92px;
}

.newsletter-details{
    font-family: bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.307px;
}


.newsletter-pic-wrapper{
    margin-right: -80px;
    width: 50%;
}
.newsletter-pic-wrapper img{
    width: 85%;

}

.email-input{
    background-color: #5d5d6f00;
    color: #F1F1F3;
    display: inline-flex;
    padding: 15px 80px 15px 16px;
    border-radius: 5px;
    border: 1px solid var(--dark-dark, #5D5D6F);
    align-items: center;
    font-size: 16px;
    font-family: bitter;
    width: 23vw;
}
.submit-button-homepage{
    padding: 15.5px 20px;
    margin-left: -18px;
    font-family: bitter;
    color: #F1F1F3;
    display: inline-flex;
    height: 49px;
    align-items: flex-start;
    border-radius: 10px;
    border: none;
    background: var(--primary-normal-active, #8B03FB);
    cursor: pointer;
}

.progression-section{
    margin-top: 15dvh;
    margin-top: 15vh;
}

.progression-header{
    color: #F1F1F3;
    width: 85dvw;
    width: 85vw;
    margin: 10vh auto 2vh auto;
    text-align: left;
    font-family: bitter;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 150% */
    letter-spacing: 6.221px;
}



.progression{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85vw;
    margin: auto;
    position: relative;
}


/* .progression img{
    
} */

.basic-card img, .intermediate-card img, .advanced-card img{
    width: 99%;
    margin: auto;
    border-radius: 14px;
    background-color: rgba(251, 30, 30, 0);
}

.basic-image-wrapper, .intermediate-image-wrapper, .advanced-image-wrapper{
    background-color: #1C0132;
}

.basic-card, .intermediate-card, .advanced-card{
    padding-bottom: 3vh;
    background-attachment: fixed;
    backdrop-filter: blur(10px);
    margin-left: 1vw ;
    border-radius: 9.269px;
    background-color: #1C0132;
    box-shadow: 4.634514331817627px 8.342124938964844px 33.368499755859375px 0px #06000b;

}

.progression-title{
    padding-top: .8vh;
    color: #F1F1F3;
    padding-left:10px ;
    font-family: bitter;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
}

.progression-detials{
    padding-left: 10px;
    margin-left: .3vw;
    padding-top: .5vh;
    color: #F1F1F3;
    font-family: inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.veiw-courses-btn{
    border-radius: 10px;
    border: 1px solid var(--dark-light, #F1F1F3);
    color: #F1F1F3;
    text-align: center;
    padding: 10px 24px;
    margin-top: 7dvh;
    margin-top: 7vh;
    margin-left: 1vw;
    font-size: 16px;
    background-color: #1C0132;
    cursor: pointer;
    display: inline-flex;
}

.progression-circle1, .progression-circle2{
    width: 20vw;
    height: 20vh;
    flex-shrink: 0;
    border-radius: 317px;
    background: #8B03FB;
    filter: blur(150.13641357421875px);
    position: absolute;
    z-index: -1;
}

.progression-circle1{
    left: 15vw;
    bottom: 0;
}
.progression-circle2{
    right: 15vw;
    bottom: 0;
}





/****Mobile****/



@media screen and (max-width: 1337px){
    .newsletter{
        width: 85vw;
    }
    .newsletter-card{
        width: 90%;
    }

}


@media screen and (max-height:1200px ), ( max-width:828px){

    .submit-button{
        padding: 1.5vh 1.7vw;
    }



    .newsletter{
        padding-bottom: 0;
    }



}

@media screen and (max-width:912px){
    .newsletter{
        padding-bottom: 0;
        margin: 40px auto 0px auto;
    }
    .submit-button-homepage{
        margin-left: -20px;
    }
}



@media screen and (max-width:900px) {


    .submit-button{
        font-size: 12px ;
    }

    .hero-header{
        font-size: 56px;
    }

    .hero-details{
        font-size: 18px;
        line-height: 16px;
        width: 70%;
    }

    .email-input{
        padding: 16px 30px 16px 16px;
    }

    .newsletter-details{
        font-size: 14px;
    }

    .newsletter-header{
        line-height: 0;
        font-size: 24px;
    }

    /* .newsletter-pic-wrapper{
        margin-right: -70px;

    } */


    .newsletter{
        padding-bottom: 0;
    }
    
}


@media screen and (max-width:780px){

    .hero-header{
       font-size: 40px;
       font-style: normal;
       font-weight: 800;
       letter-spacing: 3px;
    }


    .hero-details{
        width: 60%;
        text-align: center;
        font-size: 16px;
        font-style: normal;
    }

    .newsletter{
        width: 90vw;

    }

    .newsletter-pic-wrapper{
        display: none;
    }

    .newsletter-card{
        width: 100%;
    }
    .newsletter-content{
        width: 80%;
        margin: auto;
    }

    .newsletter-header{
        font-family: bitter;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
    }

    .newsletter-details{
        font-family: bitter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }


    .email-input{
        width: 70%;
        padding-top:20px ;
        padding-bottom:20px ;
    }

        .submit-button-homepage{
        height: 58px;
        padding: 20px 35px;
        margin-left: -70px;
        font-size: 14px;
    }




    .progression{
        margin-right:1px ;
        width: 95vw;
        overflow-x: auto;
        padding-bottom: unsets;
        overflow-y: hidden;

    }

    .progression-header{
        font-size: 52px ;
        margin-top: 0;
        margin-bottom: 16px;
    }

    .basic-card, .intermediate-card, .advanced-card{
        display: inline-block;
        min-width: 50vw;
    }




    .progression-title{
        padding-left:3vw ;
        font-size: 4vw;
        font-style: normal;
        font-weight: 700;
    }
    
    .progression-detials{
        padding-left: 3vw;
        font-family: bitter;
        font-size: 2.4vw;
    }
    
    .veiw-courses-btn{
        padding: 1.5vh 3.2vh;
        margin-top: 5vh;
        margin-top: 5vh;
        margin-right: 1vw;
        font-size: 1.9vw;
        background-color: #1C0132;
        cursor: pointer;
        display: inline-flex;
    }
    
}


@media screen and (max-width:620px) {

    .newsletter-content h2{
        font-size: 32px;
    }

    .newsletter-details{
        font-size: 18px;
    }
}

/* .circle-home{
    height: 40vh;
    width: 40vw;
    filter: blur(0.13641357421875px);
} */



@media screen and (max-width: 540px){
    .hero-header{
        font-size: 28px;
        margin-top: 2vh;
    }

    .hero-details{
        font-size: 14px;
        width: 75vw;
    }
    .newsletter-card{
        width: 90vw;
        height: 25vh;
    }

    .newsletter-content{
        width: 90%;
        height: 20vh;
    }

    .newsletter-content h2{
        padding-bottom: 10px;
        width: 100%;
        font-size: 29px;
        font-weight: 800;
    }

    .newsletter-details{
        padding-top:0px ;
        font-family: Libre Franklin;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-bottom: 20px;
    } 


    .email-input{
        width: 80%;
    }


    .progression-header{
        font-size: 32px;
        letter-spacing: 4px;
        font-weight: 800;
    }


    .progression-section{
        margin-top: 8vh;
    }

}

@media screen and (max-width:414px) {

    .hero-details{
        width: 85vw;
    }

    .circle-home{
        height: 40vh;
        width: 40vw;
        /* filter: blur(60.13641357421875px); */
    }
    .email-input{
        height: 47px
    }

    .submit-button-homepage{
        padding: 16px 32px;
        height: 49px;
    }
}

@media screen and (max-width:366px) {
    .hero-header{
        font-size: 22px;
    }
}

@media screen and (max-width:330px) {
    .newsletter-content h2{
        font-size: 22px;
        padding-top: 20px;
    }
    .newsletter-details{
        font-size: 12px;
    }

    .email-input{
        height: 28px;
        width: 80%;
    }

    .submit-button-homepage{
        font-size: 12px;
        height: 90%;
        width: 40%;
        padding-left: 20px;

    }
}

@media  screen and (max-width:282px) {
    .hero-header{
        font-size: 20px;
        width: 92vw;
    }
}



