.course-content-wraper{
    background: #F3E6FF;
}
.course-content{
    padding-top: 5%;
    padding-bottom: 3%;
    width: 80%;
    margin: auto;
    background: #F3E6FF;
}
.headers{
    display: flex;
    justify-content: space-between;
}


.course-content-heading{
    color: #0b0b0b;
    font-family: bitter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.view-all-cta{
    color: #8B03FB;
    font-family: bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.header-and-drop-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-and-drop-btn h2{
    font-family: bitter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
}

.drop-down-button-open{
    cursor: pointer;
    transform: rotate(180deg);
    transition: ease-in-out .2s;
}
.drop-down-button-close{
    transform:none;
    cursor: pointer;
}

.each-section{
    border-top: 1px solid #D19AFE;
}

.course-list{
    list-style-type:disc;
    font-size:12rem ;
    
}

.course-list li{
    margin-left: 3%;
    font-size: 16px;
    font-family: inter;
    line-height: 1.7em;
    font-weight: 460;
}

hr{
    background-color: #8B03FB;
    color: #D19AFE;
    height: 1px;
    opacity: .4;
}

#course-list-close{
    display: none;
}

#course-list-open{
    display: block;
    
}

@media screen and (max-width:600px) {
    .course-content-heading{
        font-size: 16px;
    }

    .header-and-drop-btn h2 {
        font-size: 14px;
    }

    .course-list li{
        font-size:12px ;
    }

    .view-all-cta{
        font-size: 14px;
        /* width: 25%; */

    }
}