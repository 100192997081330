.footer-wrapper{
    width: 100%;
    background-color: #1C0132;
}

.footer{
    width: 80%;
    margin: auto;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.links-group{
    width: 50%;
}
.connect-header{
    margin-top: 20px;
    color:  #F1F1F3;
    font-family: bitter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}

.foot-nav-header{
    font-family: bitter;
}

.our-socials, .footer-nav-links {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    
}
.our-socials{
    width: 50%;
}

.footer-nav-links{
    width: 70%;
    font-family: inter, sans-serif;
    font-weight: 600;
    font-size: 18px;
}
.social img{
    width: 26px;
}

.footer-navigation{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top:4vh ;
}


.footer-nav-links a{
    text-decoration: none;
    color: #F1F1F3;
    font-family: bitter;
    height: 10px;
    margin-bottom: 7vh;
}

.log-or-sign{ 
    display: flex;
    justify-content: space-between;
    width: 30%;
    font-family: inter;
    font-style: normal;
    margin-bottom: 4vh;
}

.log-or-sign a{
    font-size: 16px;
    font-weight: 500;
    color: #F5FDFE;
    text-decoration: none;
    font-size: 16px;
    font-family: inter;
}


.footer-login, .footer-signup{
    height: 4vh;
    border-bottom: 2px solid #8B03FB;
}


.footer-newsletter-header{
    font-family: bitter;
}

.footer-email-input{
    color: #F1F1F3;
    height: 4.2vh;
    background-color: #5d5d6f00;
    display: inline-flex;
    padding: 22px 20px 22px 16px;
    border-radius: 5px;
    border: 1px solid #5D5D6F;
    align-items: center;
    font-size: 16px;
    font-family: inter;
    width: 25vw;
}


.submit-button{
    margin-left: -50px;
    color: #F1F1F3;
    display: inline-flex;
    padding: 16px 32px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid  #5D5D6F;
    background:  #8B03FB;
    cursor: pointer;

}







/* @media screen and (max-width:1200px) {
    .footer-newsletter-header{
        font-size: 36px;
    }

} */


@media screen and (max-width:950px) {
    .footer{
        width: 85vw;
    }

    .our-socials{
        width: 75%;
    }
    .footer-newsletter-header{
        font-size: 24px;

    }



    .footer-navigation{
        width: 90%;
    }

    .footer-nav-links a{
        width: 5%;
    }

    .question{
        padding-left: 20px;
    }



}

@media screen and (min-height:800px ), ( max-width:828px){
}





@media screen and (max-width: 760px) {
    .footer{
        display: flex;
        flex-direction: column;
        align-items: unset;
        position: relative;
        
    }

    .links-group{
        width: 90%;
    }
    .social img{
        width: 28px;
    }
    .subscribe-to-news-footer{
        padding-bottom: 5%;
    }



    .footer-email-input{
        width: 70%;
    }

    .log-or-sign{
        padding-bottom: 10px;
    }




}



@media screen and (max-width:430px){

    .connect-header{
        font-size: 18px;
        line-height: 16px;
    }

    .social img{
        width: 28px;
    }

    .footer-email-input{
        padding: 20px 20px 20px 16px;
        width: 70%;
        font-size: 16px;
    }

    .footer-newsletter-header{
        font-size: 18px;
        padding-bottom: 15px;
        
    }

    .footer-nav-links a{
        font-size: 16px;
    }
    .log-or-sign span{
        padding-right: 20px;
    }


}

@media screen and (max-width:392px) {
    .footer-navigation{
        padding-top: 2vh;
        width: 100%;
    }
    .our-socials img{
        width: 28px;
    }



    /* .submit-button{
        margin-left: -70px;
    } */
}