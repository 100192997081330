.about-section{
    color:  #F1F1F3;
    width: 85vw;
    margin: auto;
}

.our-experience-header, .goal-header, .mision-header{
    font-family: bitter;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    width: 85vw;    
    margin-top: 10vh;
    font-weight: 700;
    letter-spacing: 6.221px;
    line-height: 130%;
}

.our-experience-details, .mission-detials, .our-goal-detials{
    margin-left: .3vw;
    color:  #F1F1F3;
    font-family: inter;
    font-size: 20px;
    font-style: normal;
    line-height: 27px;
    font-weight: 380;
    letter-spacing: 0.70px;
    width: 85%;
    
}

.span{
    padding-top: 2%;
}

#our-experience-details-mobile{
    display: none;
}





.core-values-cards{
    position: relative;
    margin-bottom: 10%;
}

.card-text-wrapper, .value-header, .card-details, .card-image-wrapper, .card-image-wrapper img, .connect-with-us, .connect-header, .subscribe-to-news-footer, .footer-navigation, .footer-newsletter, .log-or-sign, .login{
    background-color: rgba(0, 0, 0, 0);
    color: #F1F1F3;
}


.card-circle1, .card-circle2, .card-circle3, .card-circle4, .card-circle5{
    position: absolute;
    width: 20vw;
    height: 20vh;
    flex-shrink: 0;
    border-radius: 263px;
    background: #8B03FB;
    filter: blur(80px);
    z-index: -1;


}
.card-circle1{
    right: 17vh;
    top: 30vh;


}
.card-circle2{
    right: 17vh;
    bottom: 40vh;

}
.card-circle3{
    left: -10vw;
    top: 30vh;
}

.card-circle4{
    left: 12vw;
    bottom: 15vh;
}

.card-circle5{
    top: 80vh;

}

.each-card{
    margin: auto;
    display: flex;
    align-items: center;
    width: 535px;
    height: 311px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 3px solid rgba(144, 151, 211, 0.99);
    background: linear-gradient(109deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.04) 100%);
    box-shadow: 0px 0px 50px 25px rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(20px);
}

.core-value-main-header, .blockbit-partners-header{
    color: var(--dark-light, #F1F1F3);
    font-family: bitter;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    margin:40px 0; 
}


.card-text-wrapper{
    width: 70%;
    height: 50%;
    padding-left: 20px;
}

.card-details{
    color: #F1F1F3;
    font-family: inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight:600;
    line-height: 24px;
    padding-top: 16px;
}


.card-text-wrapper h2{
    font-size: 28px ;
    font-family: bitter;
}


.cards{
    margin: auto;
    width: 85vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10vh;
}



.about-section-two{
    width: 98vw;
    margin: auto;
}

.associates{
    width:87%;
    margin:auto;
    font-family: bitter;
}



@media screen and (max-width: 1300px) {
    .each-card{
        width: 95%;
        height: 230px;
    }

    .value-header{
        font-size: 28px;
    }
    
    .card-details{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px
    }
    
    .card-image{
        width: 75%;

    }


}



@media screen and (max-width : 1000px) {
    .core-value-main-header, .our-experience-header,.goal-header,.mision-header{
        font-size: 36px ;
    }

    .our-experience-details, .our-goal-detials, .mission-detials{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }


}



@media screen and (max-width:900px) {

    .value-header{
        font-size: 20px;
    }
    
    .card-details{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px
    }
    
    .card-image{
        width: 80%;

    }

}


@media screen and (min-height:1000px ), ( max-width:828px){


}




@media screen and (min-width:815px ){
    .value-header{
        font-size: 24px;
    }
    
    .card-details{
        font-size: 12px;
        font-style: normal;
        font-weight: 200;
        line-height: 15px
    }
    
    .card-image{
        width: 85%;

    }

}



@media screen and (max-width: 670px) {

    #our-experience-details-mobile{
        display: block;
    }

    #our-experience-details-dekstop{
        display: none;
    }
    .about-section{
        padding-left: 3%;
    }

    .core-value-main-header, .our-experience-header,.goal-header,.mision-header, .core-value-main-header, .blockbit-partners-header{
        font-size: 38px ;
        margin-top: 4vh;
    }

    .our-experience-details, .our-goal-detials, .mission-detials{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: 100%;
    }

    
    .value-header{
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
    }
    
    .card-details{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
    
    
    .cards{
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    
    .card-image{
        width: 90%;
    }

    .newsletter-header, .connect-header{
        font-size: 3vw;
    }


    .about-section{
        width: 95vw;
    }
    .core-values{
        width: 100%;
    }

    .card-image{
        padding-left: 20px;
    }

    .value-header{
        font-size: 26px;
    }
    .card-details{
        font-size: 12px;
    }

    .core-value-main-header, .our-experience-header,.goal-header,.mision-header, .blockbit-partners-header{
        font-size: 26px ;
    }

    .cards{
        row-gap: 26px;
    }
}


@media screen and (max-width:412px) {
    .our-experience-details, .our-goal-detials, .mission-detials{
        font-size: 14px;
        line-height: 20px;
        width: 100%;

    }

    .our-experience-header, .goal-header, .mision-header{
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; 
        margin-top: 4vh;
    }

    
}