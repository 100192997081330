.signup{
    margin: auto;
    width: 85vw;
}



.signup-form{
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    flex-direction:column ;
    margin: 150px auto 0;
    align-content: center;
    justify-content: center;
}

.first-and-lastname{
    display: flex;
    gap: 20px;
}

.first-and-lastname input{
    background-color: rgba(93, 93, 111, 0);
    padding: 16px 170px 16px 16px;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid var(--dark-dark, #5D5D6F);
    margin-bottom: 15%;
    color: #F1F1F3;
    position: relative;
}

.firstName-label , .lastName-label{
    color: #F1F1F3;
    font-family: Libre Franklin;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding-left: 3px;
}


.email, .phone, .password{
    color: #F1F1F3;
    padding: 16px 295px 16px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 26px;
    gap: 12%;
    border-radius: 10px;
    border: 1px solid var(--dark-dark, #5D5D6F);
    background-color: #5d5d6f00;
}

.submit{
    margin: auto;
    display: flex;
    color: #F1F1F3;
    width: 80%;
    padding: 16px 248px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    background-color:  #8B03FB;
}


.alternative{
    padding:30px 0 ;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    text-align: center;
}

.alternative p{
    color: #F1F1F3;
    font-family: Libre Franklin;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 12px;
}

.email-label, .phone-label, .password-label{
    color: #F1F1F3;
    padding: 0px 0px 8px 10px;
}


@media screen and (max-width:780px) {

    .first-and-lastname input{
        width: 100%;
        padding: 16px 70px 16px 16px;
    }
}


@media screen and (max-width:526px) {

    .signup-form{
        width: 90%;
        margin-top: 15px;
    }

    .first-and-lastname{
        flex-direction: column;
        width: 80%;
        gap: 0;
    }

    .first-and-lastname{
        width: 75vw;
    }

    .first-and-lastname input{
        width: 100%;
        padding: 16px 16px 16px 16px;
        margin-bottom: 32px;
    }

    .email, .phone, .password{
        width: 100%;
        padding: 16px 17px 16px 16px;
    }
    .submit{
        padding: 16px 120px;
    }
}

